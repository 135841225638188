import HomeCarousel from '../componets/home/home-carousel';
import ConductSessionButton from '../componets/home/conduct-session-button';
import HomeDashbaord from '../componets/home/home-dashboard';
import RecentlyViewedCourses from '../componets/home/recently-viewed-courses';
import { useSelector } from 'react-redux';
import { isMassReducer, userDetailReducer } from '../redux/reducers';

const Home = () => {
    const isMass = useSelector(isMassReducer);
    const detail = useSelector(userDetailReducer);

    return <div className="home d-flex flex-col gap10" style={{ width: '100%', position: 'relative' }}>
        <HomeCarousel />
        {(isMass && detail['role_id'] !== '9') && <ConductSessionButton />}
        <HomeDashbaord teacher={detail['role_id'] === '10'} />
        <RecentlyViewedCourses />
    </div >
}

export default Home;