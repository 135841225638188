import { Maximize } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { docFilesFormatList,imgFilesFomatList } from "../../utils/constants";
import { getFileExtension } from "../../utils/utils";

const ScormPlayerCard = ({
  src,
  scormKey,
  title,
  handleScormEvent = (e) => {},
  quest
}) => {
  const iframeILM = useRef(null);
  const iframeParent = useRef();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [iframeSrc, setIframeSrc] = useState(src);
  const [timeoutError, setTimeoutError] = useState(false);

  // go to full screen
  const fullScreenFunction = () => {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (iframeParent.current.requestFullscreen) {
        iframeParent.current.requestFullscreen();
      } else if (iframeParent.current.mozRequestFullScreen) {
        iframeParent.current.mozRequestFullScreen();
      } else if (iframeParent.current.webkitRequestFullScreen) {
        iframeParent.current.webkitRequestFullScreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  };

  // remove listener while leaving the component
  useEffect(() => {
    return () => {
      window.removeEventListener("message", handleScormEvent);
    };
  }, []);

  // Set a timeout to handle long loading
  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        setTimeoutError(true);
        setLoading(false);
      }, 10000); // 10 seconds timeout

      return () => clearTimeout(timer); // Cleanup the timer on unmount
    }
  }, [loading]);

  // set new source
  useEffect(() => {
    if (docFilesFormatList.includes(getFileExtension(src))) {
      var replaced = src.replace(
        "/cdn",
        "https://frigga-ulipsu-prod.azureedge.net"
      );
      var res = `https://docs.google.com/gview?url=${encodeURIComponent(
        replaced
      )}&embedded=true#toolbar=0&scrollbar=0`;
      setIframeSrc(res);
    } else {
      setIframeSrc(src);
    }
  }, [src]);

  return (
    <div className={`${quest ?'quest_container' :'player__container' }`} ref={iframeParent}>
      {src !== null && src.length > 0 && !timeoutError && (
      imgFilesFomatList.includes(getFileExtension(src)) ? (
        <img
          key={scormKey}
          ref={iframeILM}
          title={title}
          src={iframeSrc}
          style={{ width: "100%", height: "100%", objectFit: "fill", display: "block" }}
          onLoad={() => {
            setLoading(false);
          }}
          onError={() => {
            setError(true);
            setLoading(false);
          }}
        />
      ) : (
        <iframe
          loading="lazy"
          key={scormKey}
          ref={iframeILM}
          title={title}
          src={iframeSrc}
          onLoad={() => {
            setLoading(false);
            window.addEventListener("message", handleScormEvent);
          }}
          onError={() => {
            setError(true);
            setLoading(false);
          }}
        />
      )
      )}

      {/* video source is empty or null */}
      {(src === null || src.length <= 0 || error || timeoutError) && (
        <div
          className="d-flex flex-col align-center justify-center elevation2"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          {timeoutError ? (
            <span>Failed to load the document. Please try again!</span>
          ) : (
            <>
              <span>Couldn't load media. Please try again! or</span>
              <span>Contact Support</span>
            </>
          )}
        </div>
      )}
      {/* loader */}
      {!error && loading && !timeoutError && (
        <div className="spinner__parent">
          <div className="spinner" />
        </div>
      )}
      {/* go to full screen */}
      {src !== null && !timeoutError && (
        <div
          className="d-flex align-center justify-center radius1 p1"
          style={{
            position: "absolute",
            bottom: "16px",
            right: "16px",
            background: "rgba(0, 0, 0, .4)",
            cursor: "pointer",
          }}
          onClick={() => fullScreenFunction()}
        >
          <Maximize size={20} color="white" />
        </div>
      )}
    </div>
  );
};

export default ScormPlayerCard;

// var base =
//   replaced.endsWith(".pdf") || replaced.endsWith(".PDF")
//     ? "https://drive.google.com/viewerng/viewer?embedded=true&"
//     : "https://docs.google.com/gview";

/* <embed id="fgh" src="http://infolab.stanford.edu/pub/papers/google.pdf" type="application/pdf" width="400" height="400"></embed> */
/* http://infolab.stanford.edu/pub/papers/google.pdf#toolbar=0&navpanes=0&scrollbar=0 */
/* const _pdf = 'https://ulipsutest.s3.ap-south-1.amazonaws.com/uploads/projects/project_file/8f48ba716a4af57ae9b2e0308c871c00.pdf'; */
