import { ChevronRight, LogOut } from 'lucide-react';
import { CONDUCT_SESSION_PATH, PROFILE_PATH } from '../utils/route-constants'
import { NavLink } from 'react-router-dom';
import { PROFILE_MENUS as menus } from '../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/auth-slice';
import ConductSession from '../assets/conduct-session.png';
import { isMassReducer, userDetailReducer } from '../redux/reducers';

const ProfileMenuCard = ({ name }) => {

    const dispatch = useDispatch();
    const isMass = useSelector(isMassReducer);
    const detail = useSelector(userDetailReducer);

    return <div className='profile__menu radius3'>
        <div className='profile__menu__card p2 pb4 radius3 d-flex flex-col gap3'>
            <div className="profile__info py1 px2 radius2">
                <div className="profile__username large__body__bold" style={{ textAlign: 'end' }}>Hi 👋 {name}</div>
                <NavLink className="d-flex justify-end large__label2__regular" to={PROFILE_PATH}>
                    <span style={{ color: '#696F79' }}>Your Profile</span>
                    <ChevronRight size={18} color='#696F79' />
                </NavLink>
            </div>
            {( isMass && detail['role_id'] !== '9') && <div className='p1 radius2 conduct__session__button'>
                <NavLink className="d-flex align-center gap2" to={CONDUCT_SESSION_PATH}>
                    <div className='depth3' style={{ height: '32px', width: '32px', borderRadius: '6px', padding: '6px', background: '#F6F5FF' }}>
                        <img src={ConductSession} style={{ height: '20px' }} alt='Conduct Session' />
                    </div>
                    <div className='large__body__bold' style={{ color: '#E539B5' }}>Conduct Session</div>
                </NavLink>
            </div>}

            <div className="d-flex flex-col gap1">
                {menus.map((menu, i) => <NavLink key={i} className="profile__menu__item py1 px2 d-flex justify-between align-center" to={menu.path}>
                    <span className='large__label2__regular' style={{ color: '#3E444F' }}>{menu.title}</span>
                    {menu.icon}
                </NavLink>)}
            </div>

            <div className="profile__menu__item py1 px2 d-flex justify-between align-center" onClick={() => dispatch(logout())}>
                <span className='large__label2__regular' style={{ color: '#3E444F' }}>Log Out</span>
                <LogOut color='#7364EC' />
            </div>
        </div>
    </div>
}
export default ProfileMenuCard;