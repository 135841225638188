import { useNavigate } from "react-router-dom";
import { CONDUCT_SESSION_PATH } from "../../utils/route-constants";
import ConductSession from '../../assets/mode-conduct-session.png';
import TecherPortal from '../../assets/teacher-portal.png';
import { useSelector } from "react-redux";
import { isMassReducer, userDetailReducer } from "../../redux/reducers";

const ModeSelection = () => {
    const navigate = useNavigate();
    const detail = useSelector(userDetailReducer);
    const isMass = useSelector(isMassReducer);


    return (
        <div className="mode__selection">
            <div className="mode__selection__cards">
                {( isMass && detail['role_id'] !== '9') && <div className="mode__selection__card">
                    <div className="mode__selection__img">
                        <img src={ConductSession} alt="Conduct Session" />
                    </div>
                    <div className="large__header3__bold" >Conduct Session</div>
                    <p className="large__body__regular" style={{ color: '#696F79' }}>Display Skill Programs & ILMs in real-time, enhancing engagement and communication with students.</p>
                    <button className="primary neo" onClick={() => navigate(`/${CONDUCT_SESSION_PATH}`)}>Conduct Session</button>
                </div>}

                <div className="mode__selection__card">
                    <div className="mode__selection__img">
                        <img src={TecherPortal} alt="Access Teacher Portal" />
                    </div>
                    <div className="large__header3__bold">Access Teacher Portal</div>
                    <p className="large__body__regular" style={{ color: '#696F79' }}>Get a detailed view of student's performance, including attendance, grades, and participation levels. View course material in advance.</p>
                    <button className="primary neo" onClick={() => navigate('/')}>Access Teacher Portal</button>
                </div>
            </div>
        </div>
    );
}

export default ModeSelection;